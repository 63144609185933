@import "~react-datepicker/dist/react-datepicker.css";

.Toastify__progress-bar--success {
  @apply bg-sf-vartana-green;
}
.Toastify__progress-bar--error {
  @apply bg-red-600;
}

.react-datepicker-wrapper {
  display: block;    
}

.react-datepicker__day--selected,
.react-datepicker__day--keyboard-selected {
  background-color: #F1F5FD !important;
  color: #121212;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  input[type=number]::-webkit-inner-spin-button, 
  input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none; 
    margin: 0; 
  }
}

@layer utilities {
  .field-label {
    @apply not-italic;
    @apply text-xs;
    @apply leading-5;
  }
  .field-text {
    @apply not-italic;
    @apply text-sm;
  }

  .sf-h1 {
    @apply not-italic;
    @apply text-base;
    @apply font-bold;
  }

  .sf-h2 {
    @apply not-italic;
    font-size: 1.125rem;
    line-height: 1.688rem;
    @apply font-bold;
  }

  .sf-p-small {
    @apply not-italic;
    @apply text-xs;
  }

  .sf-p-small-bold {
    @apply not-italic;
    @apply text-xs;
    @apply font-bold;
  }

  .sf-text-link {
    @apply not-italic;
    font-size: 0.8125rem;
    @apply leading-6;
  }

  .sf-text-link-bold {
    @apply not-italic;
    font-size: 0.8125rem;
    @apply leading-6;
    @apply font-semibold;
  }
  
  .sf-button-text {
    @apply not-italic;
    font-size: 0.875rem;
  }

  .sf-body {
    @apply not-italic;
    font-size: 0.875rem;
    line-height: 1.313rem;
  }

  .normal-text {
    @apply not-italic;
    @apply font-normal;
    @apply text-sm;
    @apply tracking-wide;
  }

  .card-shadow {
    box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.28), 0px 8px 16px rgba(0, 0, 0, 0.1);
  }

  .indexed-step {
    @apply bg-sf-vartana-gray-30;
    @apply rounded-full;
    @apply w-4;
    @apply h-4;
  }

  .complete {
    @apply bg-sf-vartana-blue-50;
    box-shadow: 0px 0px 0px 8px rgba(27, 109, 255, 0.3);  
    @apply rounded-full;
    @apply w-4;
    @apply h-4;
  }

  .inactive {
    @apply bg-sf-vartana-coral;
    box-shadow: 0px 0px 0px 8px rgba(255, 138, 114, 0.3);  
    @apply rounded-full;
    @apply w-4;
    @apply h-4;
  }

  .finished {
    @apply bg-sf-vartana-green;
    box-shadow: 0px 0px 0px 8px rgba(31, 193, 152, 0.3);  
    @apply rounded-full;
    @apply w-4;
    @apply h-4;
  }

  .sf-card-title {
    @apply text-lg;
    @apply font-medium;
    @apply text-lg;
    font-family: Inter, ui-sans-serif;
  }

  .sf-card-subtitle {
    @apply not-italic;
    @apply font-normal;
    @apply text-base;
    font-family: Inter, ui-sans-serif;
  }

  .sf-card-subtitle-bold {
    @apply not-italic;
    @apply font-bold;
  }

  .sf-field-helper-text{
    font-size: 10px;
  }
}

.toast-msg-shadow {
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.35), 0px 4px 16px rgba(0, 0, 0, 0.2);
}

.lds-dual-ring {
  border-radius: 50%;
  border-color: #1B6DFF transparent #1B6DFF transparent;
  animation: lds-dual-ring 1.2s linear infinite;
  @keyframes lds-dual-ring {
      0% {
          transform: rotate(0deg);
      }
      100% {
          transform: rotate(360deg);
      }
  }
}

.modal {
  @apply fixed;
  @apply inset-0;
  @apply bg-sf-vartana-gray-50;
  @apply bg-opacity-80;
}

@media (min-width: 1024px) {
  .lg\:max-card-width { 
    width: 1000px;  
  }
}